import React from 'react'

import { Select } from 'styles/global'
import { regions } from 'datas/regions'

const { Option } = Select

type Props = {
  setSelectedRegion: any
  isMultiple?: boolean
  style?: any
  defaultValue?: any
}

const RegionSelect = ({ setSelectedRegion, style, isMultiple, defaultValue }: Props) => {
  return (
    <Select
      defaultValue={defaultValue}
      mode={isMultiple && 'tags'}
      allowClear
      showSearch
      width="150px"
      responsive="width: 100%;"
      style={style}
      placeholder="Région"
      optionFilterProp="children"
      onChange={(value) => setSelectedRegion(value)}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value={'*'}>Toutes les régions</Option>
      {regions.map((region, index) => (
        <Option key={index} value={region}>
          {region}
        </Option>
      ))}
    </Select>
  )
}

export default RegionSelect
