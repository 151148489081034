import React, { Dispatch, SetStateAction, LegacyRef, useState, useEffect } from 'react'
import { message, Modal, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import type { UploadFile } from 'antd/es/upload/interface'
import { useTranslation } from 'react-i18next'

export type UploadImageProps = {
  fileList: any
  setFileList: any
  maxImages: number
  type?: string
}

const UploadImage = ({ fileList, setFileList, maxImages, type }: UploadImageProps) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const { t } = useTranslation()

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  useEffect(() => {
    if (fileList?.length) {
      setFileList(
        fileList.map((file) =>
          file.base64?.includes('https://') ? { ...file, base64: '', url: file.base64 } : file,
        ),
      )
    }
  }, [])

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M && file.status !== 'removed') {
      message.error('Image must smaller than 2MB!')
      return false
    }
    return true
  }

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file?.url || '')
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  const onChange = (info) => {
    if (info.file?.status === 'removed') {
      setFileList(info.fileList)
    } else {
      // Get this url from response in real world.
      if (beforeUpload(info.file)) {
        try {
          getBase64(info.file, (imageUrl) => {
            setFileList([{ url: imageUrl, imageUrl, base64: imageUrl, name: info.file.name }])
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  const handleCancel = () => setPreviewVisible(false)

  return (
    <>
      <Upload
        beforeUpload={() => false}
        id="upload-image"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={type !== '.pdf' ? handlePreview : () => null}
        accept={type || '.png,.jpg,.jpeg'}
      >
        {fileList.length < maxImages && `+ ${t('import')}`}
      </Upload>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadImage
