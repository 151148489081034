import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'

import { CountrysSelect, DepartmentsSelect, RegionSelect } from 'components'
import { Button, Select } from 'styles/global'
import { Container } from './style'

const { Option } = Select

export type FilterParams = {
  country: string
  region: string
  department: string
  contractType: string
}

type Props = {
  setFilterParams: Dispatch<SetStateAction<FilterParams>>
  filterParams?: FilterParams
}

const Filter = ({ setFilterParams, filterParams }: Props) => {
  const [localParams, setLocalParams] = useState<FilterParams>(
    filterParams || {
      country: '',
      region: '',
      department: '',
      contractType: '',
    },
  )
  return (
    <Container style={{ flexDirection: 'row' }} responsive="width: 95vw" width="70vw">
      <CountrysSelect
        defaultValue={localParams.country}
        style={{ margin: '15px' }}
        setSelectedCountry={(value) =>
          setLocalParams({ ...localParams, country: value === undefined ? '' : value })
        }
      />
      <RegionSelect
        defaultValue={localParams.region}
        style={{ margin: '15px' }}
        setSelectedRegion={(value) =>
          setLocalParams({ ...localParams, region: value === undefined ? '' : value })
        }
      />
      <DepartmentsSelect
        defaultValue={localParams.department}
        style={{ margin: '15px' }}
        setSelectedDepartments={(value) =>
          setLocalParams({ ...localParams, department: value === undefined ? '' : value })
        }
      />
      <Select
        defaultValue={localParams.contractType}
        allowClear
        style={{ margin: '15px', width: '240px' }}
        showSearch
        responsive="width: 100% !important;"
        placeholder="Type de contrat (CDI / CDD)"
        onChange={(value) => setLocalParams({ ...localParams, contractType: value })}
      >
        <Option value={'CDI'}>CDI</Option>
        <Option value={'CDD'}>CDD</Option>
      </Select>
      <Button.Primary margin="15px" onClick={() => setFilterParams(localParams)}>
        Rechercher
      </Button.Primary>
    </Container>
  )
}

export default Filter
