export const articles = [
  {
    id: '1',
    title: 'Le lorem ipsum ',
    description:
      "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    imagePath: 'articlesImg/article1.svg',
    pathname: '/exemple-url-article-1',
    tags: ["Offre d'emploi"],
    date: '23 september 2022',
  }
]

export const tagList = ["Offre d'emploi", 'Orthoptiste', 'Cabinet']
