export const baseUrl: string | undefined = process.env.REACT_APP_API_ENDPOINT

export const PATHS = {
  AUTH: {
    DEFAULT: 'user/login',
    REGISTRATION: 'user/registration',
    RESET: 'user/reset-password',
    VALID_RESET: 'user/reset-password/valid',
  },
  ADMIN: {
    COMPANIES: 'admin/companies',
    CANDIDATES: 'admin/candidates',
    UPDATE_USER: 'admin/user',
    LOGIN_TO_ACCOUNT: 'admin/login/user',
  },
  USER: {
    DEFAULT: 'user',
    ME: 'user/me',
    CANDIDATE: 'user/candidate',
    CANDIDATES: 'user/candidates',
    COMPANY: 'user/company',
    PAY_CVTHEQUE: 'user/payment/cv-theque',
  },
  JOB_OFFER: {
    DEFAULT: 'job-offer',
  },
  GLOBAL: {
    CONTACT: 'contact',
  },
}

export const ROUTE = (path: string): string => (baseUrl !== undefined ? `${baseUrl}${path}` : path)
