import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { H3, Input, Row, Title } from 'styles/global'
import JobOfferList from 'components/jobOfferList'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { JobOffer } from 'types'
import { FilterParams } from 'components/GlobalComponents/filter'
import JobOfferCardHome from './JobOfferCard'
import { JobOfferCard, Container } from './style'
import { Button } from 'styles/global'

import timerIcon from 'assets/icons/timer.gif'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { authUtils } from 'utils'
import { ACCOUNT_TYPES } from 'constants/index'
import Login from 'components/login'

const HomeJobOfferList = () => {
  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')
  const [filterParams, setFilterParams] = useState<FilterParams>({
    country: '',
    region: '',
    department: '',
    contractType: '',
  })

  const currentPage = 1

  const {
    isLoading,
    data: jobOffers,
    isSuccess: isDatasLoad,
  } = useQuery(['jobOffers-home'], () =>
    jobOfferService.find({ ...filterParams, page: currentPage }).then((res) => {
      setDisplayedJobOffers([...displayedJobOffers, ...res.data].splice(0, 9))
      return [...displayedJobOffers, ...res.data]
    }),
  )

  const checkLogin = (e, path) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(path)
    }
  }

  return (
    <Container>
      {isLoginFormDisplayed && (
        <Login isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      <H3
        size="40px"
        style={{ color: '#8dc8ff', marginTop: '35px', maxWidth: '90vw', marginBottom: '0px' }}
        weight="300"
      >
        Les 9 dernières offres d'emploi <b>Orthoptiste</b>
        <img
          src={timerIcon}
          style={{ width: '120px', height: 'auto', borderRadius: '50%', marginLeft: '15px' }}
        />
      </H3>
      <Row justifyContent="center" wrap>
        {displayedJobOffers?.map((jobOffer, index) => (
          <JobOfferCardHome key={`job-offer-home-${index}`} jobOffer={jobOffer} />
        ))}
      </Row>
      <Row width="100%" justifyContent="center" wrap>
        {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
          <Link
            onClick={(e) => checkLogin(e, routes.createOffer.path)}
            style={{ margin: '20px' }}
            to={routes.createOffer.path}
          >
            <Button.Primary style={{ fontSize: '20px' }}>Publier mon offre</Button.Primary>
          </Link>
        )}
        <Link style={{ margin: '20px' }} to={routes.viewOffers.path}>
          <Button.Primary style={{ fontSize: '20px' }}>Voir toutes les offres</Button.Primary>
        </Link>
      </Row>
    </Container>
  )
}

export default HomeJobOfferList
