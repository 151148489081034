import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Dropdown, Menu as AntdMenu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import { UserContext } from 'providers'
import { routes } from 'router'
import { HeaderContainer, MenuItem, Menu, BurgerMenuContainer, BurgerMenuIcon } from './style'
import { Row, Button, Text } from 'styles/global'
import { authUtils, urlParams } from 'utils'
import Login from 'components/login'

import { Link, useLocation } from 'react-router-dom'
import { ACCOUNT_TYPES } from 'constants/index'
import { useAuth } from 'hooks'
import { theme } from 'styles'

import burgerMenuImg from 'assets/icons/menu.png'

type Props = {
  isMobile: boolean
  setIsMobileMenuDisplayed: Dispatch<SetStateAction<boolean>>
}

const Header = ({ isMobile, setIsMobileMenuDisplayed }: Props) => {
  const { t } = useTranslation()
  const { user, resetUser } = useContext(UserContext)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState('')
  const [isFixedNavbar, setIsFixednavbar] = useState(false)
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  const { logout } = useAuth()
  const location = useLocation()

  const isAuth = authUtils.isAuth()

  const paramsUrl = urlParams()

  // CHEK IF WE RESET PASSWORD
  useEffect(() => {
    if (paramsUrl.resetCode) {
      setIsLoginFormDisplayed('/')
    }
  }, [])

  window.onscroll = function (e) {
    if (!isFixedNavbar && window.scrollY > 95) {
      setIsFixednavbar(true)
      const body = document.getElementById('body-wrapper')
      if (body) {
        body.style.paddingTop = '130px'
      }
    } else if (window.scrollY === 0) {
      setIsFixednavbar(false)
      const body = document.getElementById('body-wrapper')
      if (body) {
        body.style.paddingTop = '0'
      }
    }
  }

  useEffect(() => {
    setIsMobileMenuOpen(false)
    if (location.pathname === routes.home.path) {
      setCurrentPage('1')
    } else if (location.pathname === routes.viewOffers.path) {
      setCurrentPage('2')
    } else if (location.pathname === routes.createOffer.path) {
      setCurrentPage('3')
    }
  }, [location])

  const checkNeedConnexion = (e, redirect) => {
    if (!authUtils.isAuth()) {
      e.stopPropagation()
      e.preventDefault()
      setIsLoginFormDisplayed(redirect)
    }
  }

  const dropDown = (
    <AntdMenu
      items={[
        {
          key: '1',
          label: (
            <Link
              onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
              style={{ marginRight: '50px' }}
              to={routes.createOffer.path}
            >
              Déposer une offre d&#39;emploi
            </Link>
          ),
        },
        {
          key: '2',
          label: (
            <Link style={{ marginRight: '50px' }} to={routes.candidateList.path}>
              CvThèque
            </Link>
          ),
        },
        {
          key: '3',
          label: <Link to={routes.pricing.path}>Tarifs</Link>,
        },
        {
          key: '4',
          label: <Link to={routes.contact.path}>Service de chasse</Link>,
        },
      ]}
    />
  )

  return (
    <>
      {isLoginFormDisplayed && (
        <Login isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      {isMobile ? (
        <>
          <HeaderContainer
            style={
              isFixedNavbar
                ? {
                    position: 'fixed',
                    top: '0',
                    zIndex: '100',
                    width: '100vw',
                  }
                : {}
            }
          >
            <BurgerMenuContainer onClick={() => setIsMobileMenuOpen(true)}>
              <BurgerMenuIcon alt="menu burger icon" src={burgerMenuImg} />
              <Text weight="bold" size="20px" color="#4fa0e7">
                Menu
              </Text>
            </BurgerMenuContainer>
          </HeaderContainer>
          <Drawer
            className="blue-back"
            title="Emploi-orthoptiste.fr"
            placement="left"
            onClose={() => setIsMobileMenuOpen(false)}
            visible={isMobileMenuOpen}
          >
            <MenuItem
              style={{ marginBottom: '15px' }}
              isActive={window.location.pathname === routes.home.path}
            >
              <Link style={{ color: 'white' }} to={routes.home.path}>
                Accueil
              </Link>
            </MenuItem>

            {/* POUR CABINETS */}
            {(authUtils.currentRole() === ACCOUNT_TYPES.COMPANY || !isAuth) && (
              <>
                <MenuItem
                  style={{ marginBottom: '15px' }}
                  isActive={window.location.pathname === routes.createOffer.path}
                >
                  <Link
                    onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
                    style={{ color: 'white' }}
                    to={routes.createOffer.path}
                  >
                    Déposer une offre d&#39;emploi
                  </Link>
                </MenuItem>
                <MenuItem
                  style={{ marginBottom: '15px' }}
                  isActive={window.location.pathname === routes.createOffer.path}
                >
                  <Link
                    onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
                    style={{ color: 'white' }}
                    to={routes.candidateList.path}
                  >
                    CvThèque
                  </Link>
                </MenuItem>

                <MenuItem style={{ marginBottom: '15px' }}>
                  <Link style={{ color: 'white' }} to={routes.contact.path}>
                    Service de chassse
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link style={{ color: 'white' }} to={routes.pricing.path}>
                    Tarifs
                  </Link>
                </MenuItem>
              </>
            )}

            {authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE || !isAuth ? (
              <>
                <Link to={routes.viewOffers.path}>
                  <Button.Secondary style={{ height: '40px', width: '185px', marginTop: '40px' }}>
                    Offres d’emplois
                  </Button.Secondary>
                </Link>
                {isAuth ? (
                  <Link to={routes.candidateAccount.path}>
                    <Button.Secondary style={{ height: '40px', width: '210px', marginTop: '12px' }}>
                      Modifier mon profil
                    </Button.Secondary>
                  </Link>
                ) : (
                  <Button.Secondary
                    onClick={() => setIsLoginFormDisplayed(routes.candidateAccount.path)}
                    style={{ height: '40px', width: '190px', marginTop: '12px' }}
                  >
                    Déposer mon CV
                  </Button.Secondary>
                )}
                {!isAuth ? (
                  <Button.Special
                    onClick={() => setIsLoginFormDisplayed('/')}
                    style={{ height: '40px', width: '130px', marginTop: '12px' }}
                  >
                    Connexion
                  </Button.Special>
                ) : (
                  <Button.Special
                    onClick={logout}
                    style={{ height: '40px', width: '130px', marginTop: '12px' }}
                  >
                    Déconnexion
                  </Button.Special>
                )}
              </>
            ) : (
              authUtils.currentRole() === ACCOUNT_TYPES.COMPANY && (
                <>
                  <Link to={routes.myOffers.path}>
                    <Button.Secondary style={{ height: '40px', width: '185px', marginTop: '20px' }}>
                      Mes annonces
                    </Button.Secondary>
                  </Link>
                  <Link to={routes.companyAccount.path}>
                    <Button.Secondary style={{ height: '40px', width: '185px', marginTop: '12px' }}>
                      Mon compte
                    </Button.Secondary>
                  </Link>
                  {authUtils.isAuth() && (
                    <Button.Special
                      onClick={logout}
                      style={{ height: '40px', width: '140px', marginTop: '12px' }}
                    >
                      Déconnexion
                    </Button.Special>
                  )}
                </>
              )
            )}
          </Drawer>
        </>
      ) : (
        <Menu
          style={
            isFixedNavbar
              ? {
                  position: 'fixed',
                  top: '0',
                  zIndex: '100',
                  width: '100vw',
                }
              : {}
          }
          key={currentPage}
          selectedKeys={[currentPage]}
          mode="horizontal"
          className="header"
        >
          <MenuItem isActive={currentPage === '1'} style={{ marginRight: '50px' }} key="1">
            <Link style={{ color: 'white' }} to={routes.home.path}>
              Accueil
            </Link>
          </MenuItem>
          {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
            <Dropdown overlay={dropDown}>
              <a style={{ color: 'white', fontSize: '16px' }} onClick={(e) => e.preventDefault()}>
                Services aux entreprises
              </a>
            </Dropdown>
          )}
          {authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE || !isAuth ? (
            <Row key="5" style={{ position: 'absolute', right: '14vw' }}>
              <Link to={routes.viewOffers.path}>
                <Button.Secondary style={{ height: '40px', width: '185px', marginRight: '12px' }}>
                  Offres d’emplois
                </Button.Secondary>
              </Link>
              {isAuth ? (
                <Link to={routes.candidateAccount.path}>
                  <Button.Secondary style={{ height: '40px', width: '210px', marginLeft: '12px' }}>
                    Modifier mon profil
                  </Button.Secondary>
                </Link>
              ) : (
                <Button.Secondary
                  onClick={() => setIsLoginFormDisplayed(routes.candidateAccount.path)}
                  style={{ height: '40px', width: '190px', marginLeft: '12px' }}
                >
                  Déposer mon CV
                </Button.Secondary>
              )}
              {!isAuth ? (
                <Button.Special
                  onClick={() => setIsLoginFormDisplayed('/')}
                  style={{ height: '40px', width: '130px', marginLeft: '24px' }}
                >
                  Connexion
                </Button.Special>
              ) : (
                <Button.Special
                  onClick={logout}
                  style={{ height: '40px', width: '130px', marginLeft: '24px' }}
                >
                  Déconnexion
                </Button.Special>
              )}
            </Row>
          ) : (
            authUtils.currentRole() === ACCOUNT_TYPES.COMPANY && (
              <Row key="5" style={{ position: 'absolute', right: '14vw' }}>
                <Link to={routes.myOffers.path}>
                  <Button.Secondary style={{ height: '40px', width: '185px', marginRight: '12px' }}>
                    Mes annonces
                  </Button.Secondary>
                </Link>
                <Link to={routes.companyAccount.path}>
                  <Button.Secondary style={{ height: '40px', width: '185px', marginLeft: '12px' }}>
                    Mon compte
                  </Button.Secondary>
                </Link>
                {authUtils.isAuth() && (
                  <Button.Special
                    onClick={logout}
                    style={{ height: '40px', width: '140px', marginLeft: '24px' }}
                  >
                    Déconnexion
                  </Button.Special>
                )}
              </Row>
            )
          )}
        </Menu>
      )}
    </>
  )
}

export default Header
