import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Container } from './style'
import { H1, Input, Row, Title } from 'styles/global'
import { Skeletons, JobOfferCard, Filter, JobOffer } from 'components'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { urlParams } from 'utils'
import { routes } from 'router'
import { Navigate } from 'react-router-dom'

const CreateJobOffer = () => {
  const paramsUrl = urlParams()

  const jobOfferId = paramsUrl.id

  const {
    isLoading,
    data: jobOffer,
    isSuccess: isDatasLoad,
  } = useQuery(['jobOffer', jobOfferId], () => jobOfferService.findOne({ id: jobOfferId }))

  return (
    <Container>
      <Helmet>
        <title>Offre d'emploi orthoptiste | {jobOffer?.data?.officeName || ''} </title>
        <meta name="description" content={jobOffer?.data?.description || ''} />
        <meta name="keywords" content="orthoptiste, annonce, détails" />
      </Helmet>
      {isLoading ? (
        <Skeletons />
      ) : jobOffer?.data ? (
        <JobOffer jobOffer={jobOffer.data} />
      ) : (
        <Navigate to={routes.home.path} />
      )}
    </Container>
  )
}

export default CreateJobOffer
