import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'

import { Illustration } from './style'
import { Text, Button, Column } from 'styles/global'
import { routes } from 'router'
import { authUtils } from 'utils'

import badPlanImage from 'assets/illustrations/access_denied.svg'
import Login from 'components/login'

const BadPlan = () => {
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  return (
    <Modal visible={true} onCancel={() => null} footer={[]} closable={false}>
      {isLoginFormDisplayed && (
        <Login isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      <Column alignItems="center" width="100%">
        <Column alignItems="center" width="80%">
          <Text position="center" size="18px">
            Vous n'avez pas le plan nécessaire afin d'avoir accès à ce service
          </Text>
          <Illustration alt="mauvais plan illustration" src={badPlanImage} />
          <Link to={routes.pricing.path}>
            <Button.Primary>Voir les plans</Button.Primary>
          </Link>
          {!authUtils.isAuth() && (
            <>
              <Text margin="13px 0" size="18px">
                Ou
              </Text>
              <Button.Primary onClick={() => setIsLoginFormDisplayed(window.location.pathname)}>
                Se connecter
              </Button.Primary>
            </>
          )}
        </Column>
      </Column>
    </Modal>
  )
}

export default BadPlan
