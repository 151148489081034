import React, { useEffect, useState } from 'react'
import { JobOffer } from 'types'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { JobOfferCard, Element, Icon, DescribeContent } from './style'
import { Text } from 'styles/global'

import locationIcon from 'assets/icons/location.png'
import salaryIcon from 'assets/icons/salary.png'
import contractIcon from 'assets/icons/contract.png'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const JobOfferCardHome = ({ jobOffer }: { jobOffer: JobOffer }) => {
  const [htmlDescription, setHtmlDescription] = useState<string>('')
  useEffect(() => {
    try {
      const content = convertFromRaw(JSON.parse(jobOffer.description))
      //@ts-ignore
      setHtmlDescription(stateToHTML(content))
    } catch {}
  }, [])
  return (
    <Link style={{ margin: '20px' }} to={`${routes.jobOffer.path}?id=${jobOffer._id}`}>
      <JobOfferCard>
        <Text style={{ color: '#ff8e8e' }} weight="300" color="grey" margin="0 0 7px 0">
          Nouveau
        </Text>
        <Text weight="500" margin="0 0 15px 0">
          {jobOffer.officeName}
        </Text>
        <Element>
          <Icon alt="salaire" src={salaryIcon} />
          <Text margin="0 5px 0 7px" weight="bold">
            {jobOffer.salary}
          </Text>
          net /mois
        </Element>
        <Element>
          <Icon alt="localisation" src={locationIcon} />
          <Text margin="0 5px 0 7px" weight="bold">
            {jobOffer.address.region + ', '}
            {jobOffer.address.postalCode}
          </Text>
        </Element>
        <Element>
          <Icon alt="type de contrat" src={contractIcon} />
          <Text margin="0 5px 0 7px" weight="bold">
            {jobOffer.contractType}
          </Text>
        </Element>
        {htmlDescription && (
          <DescribeContent dangerouslySetInnerHTML={{ __html: htmlDescription }} ellispsis={true} />
        )}
      </JobOfferCard>
    </Link>
  )
}

export default JobOfferCardHome
