import React, { ReactElement, useState, useEffect, useContext } from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'

import { Header, Footer } from 'components'
import { windowSizes } from 'styles/mediaQuery'
import { routes } from 'router'
import { styled, mediaQuery, theme } from 'styles'
import { Route } from 'types'
import { authUtils, homeRouteUtils } from 'utils'
import { UserContext } from 'providers'
import { useAuth } from 'hooks'
import { userService } from 'services'
import { useQuery } from 'react-query'

interface Props {
  children: ReactElement
}

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${theme.color.white};
  display: flex;
  flex-direction: ${({ direction }: { direction: string }) => direction};
  padding-bottom: ${({ paddingBottom }: { paddingBottom: string }) => paddingBottom};
`

const mobileHeaderSize = 1250

const PageWrapper = ({ children }: Props): ReactElement => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user, isAuthenticated, setUser } = useContext(UserContext)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < mobileHeaderSize)
  const [isMobileMenuDisplayed, setIsMobileMenuDisplayed] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < mobileHeaderSize ? setIsMobile(true) : setIsMobile(false),
    )
    return () =>
      window.removeEventListener('resize', () =>
        window.innerWidth < mobileHeaderSize ? setIsMobile(true) : setIsMobile(false),
      )
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  const { data } = useQuery(user._id, async () => userService.me(), {
    enabled: authUtils.isAuth() && !user._id,
    onSuccess: (userRes) => {
      setUser(userRes)
    },
  })

  const currentRouteKey: string | undefined = Object.keys(routes).find(
    (key) => routes[key].path === pathname,
  )

  const currentRoute: Route | undefined =
    currentRouteKey !== undefined ? routes[currentRouteKey] : undefined

  const isAuthorizedCurrentRoute: boolean = Object.keys(routes).some(
    (key) =>
      ((routes[key].access.includes(authUtils.currentRole()) && authUtils.currentRole()) ||
        routes[key].access === '*') &&
      routes[key].path === pathname,
  )
  if (!isAuthorizedCurrentRoute) {
    return <Navigate to={routes.home.path} />
  }
  if (currentRoute?.haveMenu) {
    return (
      <Wrapper id="body-wrapper" direction="column">
        <Header isMobile={isMobile} setIsMobileMenuDisplayed={setIsMobileMenuDisplayed} />
        <Wrapper paddingBottom="80px" direction="row">
          {/*
          <Menu
            isMobile={isMobile}
            setIsMobileMenuDisplayed={setIsMobileMenuDisplayed}
            isMobileMenuDisplayed={isMobileMenuDisplayed}
          />*/}
          {children}
        </Wrapper>
        <Footer />
      </Wrapper>
    )
  }
  return (
    <Wrapper id="body-wrapper" paddingBottom="80px">
      {children}
    </Wrapper>
  )
}

export default PageWrapper

// PUBLIC PAGES
export { default as Home } from 'pages/home'
export { default as JobOfferList } from 'pages/jobOfferList'
export { default as CandidateRender } from 'pages/candidateProfile'
export { default as CompanyProfile } from 'pages/companyProfile'
export { default as JobOffer } from 'pages/jobOffer'
export { default as CGU } from 'pages/legal/CGU'
export { default as CGV } from 'pages/legal/CGV'
export { default as BlogHome } from 'pages/blog'
export { default as Pricing } from 'pages/pricing'
export { default as Contact } from 'pages/contact'

// CANDIDATE PAGES
export { default as CandidateAccount } from 'pages/candiateAccount'

// COMPANY PAGES
export { default as CreateEditJobOffer } from 'pages/createEditJobOffer'
export { default as CompanyAccount } from 'pages/companyAccount'
export { default as MyOffers } from 'pages/myOffers'

// ADMIN PAGES
export { default as AdminDashboard } from 'pages/adminDashboard'

// BLOG PAGES
export { default as Article1 } from 'pages/blog/articles/exemple/Page'
