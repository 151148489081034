import React, { FC, ReactNode, useState } from 'react'
import { cookies } from 'services'
import { COOKIES } from 'constants/index'

import { UserProviderType, UserContextType } from 'types/providers'

interface ProviderProps {
  children: ReactNode
}

const initialState = {
  _id: '',
  email: '',
  type: '',
  candidateInformations: {
    name: '',
    email: '',
    localisations: {
      countrys: [],
      regions: [],
      departments: [],
    },
    experienceYears: '',
    school: '',
    phone: '',
    profilePicture: '',
    cv: '',
    desiredSalary: '',
    contractTypes: [],
  },
  companyInformations: {
    name: '',
    email: '',
    phone: '',
    profilePicture: '',
    description: '',
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: '',
    },
  },
}

const defaultContext = {
  isAuthenticated: false,
  setIsAuthenticated: () => false,
  resetUser: () => false,
  user: initialState,
  setUser: () => true,
}

export const UserContext = React.createContext<UserContextType>(defaultContext)

UserContext.displayName = 'User'

const UserProvider: FC<ProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<UserProviderType>(initialState)

  const resetUser = (): void => {
    setUser(initialState)
    setIsAuthenticated(false)
    cookies.set([
      { key: COOKIES.TOKEN, value: '' },
      { key: COOKIES.EMAIL, value: '' },
      { key: COOKIES.TYPE, value: '' },
    ])
  }

  const context = {
    isAuthenticated,
    setIsAuthenticated,
    resetUser,
    setUser,
    user,
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export default UserProvider
