import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { loadStripe } from '@stripe/stripe-js'

import { Container, HeadContainer, Img, Card } from './style'
import { H1, Text, Row, Button, H2, H3, H4 } from 'styles/global'

import { Divider, message } from 'antd'
import { routes } from 'router'
import { Link } from 'react-router-dom'

import cvThequeImg from 'assets/illustrations/cvTheque.png'
import serviceImg from 'assets/illustrations/service.png'
import publishImg from 'assets/illustrations/publish.png'

import { IS_CVTHEQUE_DISABLED, IS_PUBLISH_OFFER_FREE } from 'constants/index'
import { authUtils } from 'utils'
import { UserContext } from 'providers'
import Login from 'components/login'
import { userService } from 'services'
import { Loader } from 'components'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
let stripe
stripePromise.then((resStripe) => (stripe = resStripe))

const Pricing = () => {
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user } = useContext(UserContext)

  const navigate = useNavigate()

  const isCvThequeAccess = authUtils.isCvThequeAccess({ user })

  const checkLogin = (e, path) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(location.pathname)
    }
  }

  const payCvTheque = (e) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(location.pathname)
    } else {
      setIsLoading(true)
      userService.payCvTheque().then(({ sessionId }) => {
        if (sessionId) {
          stripe.redirectToCheckout({ sessionId })
        } else {
          if (setIsLoading) {
            setIsLoading(false)
          }
          message.error('Une erreur est servenue, veuillez nous contacter')
        }
      })
    }
  }
  return (
    <Container>
      <Helmet>
        <title>Emploi-orthoptiste | Nos offres et services</title>
        <meta
          name="description"
          content="Découvrir nos différentes offres pour la recherche de candidats orthoptiste. Accéder à notre cvThèque des meilleurs profils orthoptiste, publier une offre d'emploi ou accéder à notre service de chercheur de tête."
        />
        <meta name="keywords" content="orthoptiste, prix, services" />
      </Helmet>
      <Loader isDisplayed={isLoading} />
      {isLoginFormDisplayed && (
        <Login
          isDisplayed={isLoginFormDisplayed}
          setIsDisplayed={setIsLoginFormDisplayed}
          isRegistration={true}
        />
      )}
      <HeadContainer>
        <H1 position="center" size="30px" weight="bold" color="white">
          Nos offres et services
        </H1>
        <H2 margin="20px 0 0 0" position="center" size="30px" weight="300" color="white">
          Trouver le service qui vous convient.
          <br />
          Tous nos prix sont sans abonnement et sans engagement
        </H2>
      </HeadContainer>
      <Row margin="-120px 0 0 0" justifyContent="center" wrap={true} width="100%">
        <Card>
          <H3 position="center" size="22px" weight="bold" color="secondary">
            Accès à la cvThèque
          </H3>
          <Img alt="cvTheque illustration" src={cvThequeImg} />
          {!IS_CVTHEQUE_DISABLED ? (
            <Text size="22px" weight="bold">
              80€
              <span style={{ fontWeight: '400', marginLeft: '5px', fontSize: '16px' }}>
                pour 2 mois
              </span>
            </Text>
          ) : (
            <Text size="22px" weight="bold">
              Bientôt disponible
            </Text>
          )}
          <Divider style={{ margin: '15px 0' }} />
          <H2>
            Accéder à la liste de tous les profils Orthoptiste sur notre site ainsi que leurs
            informations et coordonnées
          </H2>
          {!IS_CVTHEQUE_DISABLED && !isCvThequeAccess && (
            <Button.Primary onClick={payCvTheque} width="90%" margin="20px 0 0 0" size="18px">
              Obtenir l'accès
            </Button.Primary>
          )}
          {isCvThequeAccess && (
            <>
              <Text
                position="center"
                color="secondary"
                width="100%"
                margin="20px 0 10px 0"
                size="18px"
              >
                Vous avez l'accès
              </Text>
              <Link style={{ width: '100%' }} to={routes.candidateList.path}>
                <Button.Primary width="100%" size="18px">
                  Consulter
                </Button.Primary>
              </Link>
            </>
          )}
        </Card>
        <Card>
          <H3 position="center" size="22px" weight="bold" color="secondary">
            Publier votre offre
          </H3>
          <Img alt="Publier une offre" src={publishImg} />
          {IS_PUBLISH_OFFER_FREE === true ? (
            <Text size="22px" weight="bold">
              <span
                style={{
                  textDecoration: 'line-through',
                  color: '#ff5e5e',
                  fontSize: '18px',
                  marginRight: '10px',
                }}
              >
                79.00€
              </span>
              Gratuit
            </Text>
          ) : (
            <Text size="22px" weight="bold">
              79.00€
              <span style={{ fontWeight: '400', marginLeft: '5px', fontSize: '16px' }}>
                pour 1 mois
              </span>
            </Text>
          )}
          <Divider style={{ margin: '15px 0' }} />
          <H4>
            Publiez votre offre d’emploi Orthoptiste sur notre site pendant 1 mois et obtenez de la
            visibilité ultra ciblée.
            <br />
            <br />
            Laisser vos coordonnées et faites vous contacter par des profils qualifiés
          </H4>
          <Link
            onClick={(e) => checkLogin(e, routes.createOffer.path)}
            style={{ width: '90%' }}
            to={routes.createOffer.path}
          >
            <Button.Primary width="100%" margin="20px 0 0 0" size="18px">
              Découvrir
            </Button.Primary>
          </Link>
        </Card>
        <Card>
          <H3 position="center" size="22px" weight="bold" color="secondary">
            Service de chasse
          </H3>
          <Img alt="service de chasse illustration" src={serviceImg} />
          <Text>
            Faites nous confiance afin de trouver les profils qui correspondent à vos besoins
            <br />
            <br />
            Laissez nous message et nous reviendrons vers vous très rapidement
          </Text>
          <Link style={{ width: '90%' }} to={routes.contact.path}>
            <Button.Primary width="100%" margin="20px 0 0 0" size="18px">
              Nous contacter
            </Button.Primary>
          </Link>
        </Card>
      </Row>
    </Container>
  )
}

export default Pricing
