import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react'

import { routes } from 'router'
import {
  FooterContainer,
  FooterItem,
  FooterTitle,
  FooterExternalLink,
  FooterItemText,
} from './style'
import { Column, Text, Title } from 'styles/global'

import { Link, useLocation } from 'react-router-dom'
import { authUtils } from 'utils'
import Login from 'components/login'
import { ACCOUNT_TYPES } from 'constants/index'

const Footer = () => {
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  const isAuth = authUtils.isAuth()
  const isCompany = authUtils.currentRole() === ACCOUNT_TYPES.COMPANY
  const isCandidate = authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE

  const checkNeedConnexion = (e, redirect) => {
    if (!isAuth) {
      e.stopPropagation()
      e.preventDefault()
      setIsLoginFormDisplayed(redirect)
    }
  }

  return (
    <FooterContainer>
      {isLoginFormDisplayed && (
        <Login isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      <Column margin="0 45px 30px 0">
        <FooterTitle>Espace cabinet</FooterTitle>
        <FooterItem to={routes.candidateList.path}>CvThèque</FooterItem>
        <FooterItem
          onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
          to={routes.createOffer.path}
        >
          Déposer une offre d&apos;emploi
        </FooterItem>
        <FooterItem to={routes.contact.path}>Service de chasse</FooterItem>
        <FooterItem to={routes.pricing.path}>Nos offres</FooterItem>
      </Column>
      <Column margin="0 45px 30px 0">
        <FooterTitle>Espace orthoptiste</FooterTitle>
        <FooterItem to={routes.viewOffers.path}>Consulter les offres d&apos;emploi</FooterItem>
        <FooterItem
          onClick={(e) => checkNeedConnexion(e, routes.candidateAccount.path)}
          to={routes.candidateAccount.path}
        >
          Partager mon profil
        </FooterItem>
      </Column>
      <Column margin="0 45px 30px 0">
        <FooterTitle>Contact</FooterTitle>
        <FooterItemText>Tel. 06 51 18 75 01</FooterItemText>
        <FooterItemText>
          Email :<br /> job.orthoptiste@gmail.com
        </FooterItemText>
      </Column>
      <Column margin="0 45px 30px 0">
        <FooterTitle>Legal</FooterTitle>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGU.path}>
          CGU
        </FooterItem>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGV.path}>
          CGV
        </FooterItem>
      </Column>
    </FooterContainer>
  )
}

export default Footer
