import { styled, theme, mediaQuery } from 'styles'
import { Row, Text } from 'styles/global'

export const HeaderContainer = styled(Row)`
  width: 100vw;
  height: 65px;
  background-color: ${theme.color.primary};
  padding-left: 120px;
  padding-right: 120px;
  align-items: center;
  justify-content: center;
  ${mediaQuery.mobile} {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`

export const MenuItem = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    `
    font-weight: 500;
    border-bottom: 2px solid white;
    cursor: initial;
    `};
`

export const Menu = styled(Row)`
  width: 100vw;
  background: ${theme.color.primary};
  align-items: center;
  height: 60px;
  padding-left: 14vw;
  padding-right: 14vw;
`

export const BurgerMenuContainer = styled(Row)`
  background: white;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
`

export const BurgerMenuIcon = styled.img`
  height: 35px;
  width: auto;
  margin-right: 10px;
`
