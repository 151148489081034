import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container } from './style'
import { H1, Input, Row, Title } from 'styles/global'
import { Skeletons, JobOfferCard, Filter } from 'components'
import JobOfferList from 'components/jobOfferList'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { JobOffer } from 'types'
import { FilterParams } from 'components/GlobalComponents/filter'
import InfiniteScroll from 'react-infinite-scroll-component'
import { routes } from 'router'
import { urlParams } from 'utils'

const CreateJobOffer = () => {
  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isEndOfList, setIsEndOfList] = useState<boolean>(false)
  const location = useLocation()
  const paramsUrl = urlParams()
  const [filterParams, setFilterParams] = useState<FilterParams>(
    paramsUrl || {
      country: '',
      region: '',
      department: '',
      contractType: '',
    },
  )

  const navigate = useNavigate()

  const setUrlQuery = () => {
    const query = `?department=${filterParams.department || ''}&country=${
      filterParams.country || ''
    }&region=${filterParams.region || ''}&contractType=${filterParams.contractType || ''}`
    navigate(`${routes.viewOffers.path}${query}`)
    return true
  }

  const {
    isLoading,
    data: jobOffers,
    isSuccess: isDatasLoad,
  } = useQuery(['jobOffers', JSON.stringify(filterParams), currentPage], () =>
    jobOfferService.find({ ...filterParams, page: currentPage }).then((res) => {
      setUrlQuery()
      if (currentPage === 1) {
        setDisplayedJobOffers(res.data)
      } else {
        setDisplayedJobOffers([...displayedJobOffers, ...res.data])
      }
      if (!res.data?.length) {
        setIsEndOfList(true)
      }
      return [...displayedJobOffers, ...res.data]
    }),
  )

  return (
    <Container>
      <Helmet>
        <title>Les meilleures offres d'emploi orthoptiste</title>
        <meta
          name="description"
          content="Retrouver les meilleures offres d'emploi orthoptiste du marché. Trier et filtrer par régions, salaires et type de contrat (CDI/CDD) les annonces puis postuler en quelques cliques aux annonces orthoptiste qui vous intéressent"
        />
        <meta name="keywords" content="offres, emploi, orthoptiste" />
      </Helmet>
      <H1
        size="30px"
        style={{ color: '#0875BD', marginTop: '30px', maxWidth: '90vw', marginBottom: '30px' }}
        weight="bold"
      >
        Liste des offres d&apos;emploi orthoptiste
      </H1>
      <Row
        style={{ marginTop: '25px' }}
        responsive="width: 95vw"
        padding="20px 30px"
        width="70vw"
        jusitfyContent="flex-start"
      >
        {(displayedJobOffers.length || isDatasLoad) && (
          <Filter
            filterParams={filterParams}
            setFilterParams={(newParams) => {
              setCurrentPage(1)
              setFilterParams(newParams)
            }}
          />
        )}
      </Row>
      <JobOfferList
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        jobOffers={displayedJobOffers}
        isEndOfList={isEndOfList}
      />
    </Container>
  )
}

export default CreateJobOffer
