import React, { ReactElement, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { InformationsContent } from './style'
import { Card, Row, Text, H4, H3, Button, H2 } from 'styles/global'
import { JobOffer } from 'types/index'
import { Link } from 'react-router-dom'
import { authUtils } from 'utils'
import Login from 'components/login'
import { routes } from 'router'

const JobOfferCard = ({ jobOffer }: { jobOffer: JobOffer }): ReactElement => {
  const [htmlDescription, setHtmlDescription] = useState()
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  useEffect(() => {
    try {
      const content = convertFromRaw(JSON.parse(jobOffer.description))
      //@ts-ignore
      setHtmlDescription(stateToHTML(content))
    } catch {}
  }, [])

  const checkLogin = (e) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(location.pathname + location.search)
    }
  }

  return (
    <Card
      id={`job-offer-${jobOffer._id}`}
      responsive="width: 95vw"
      padding="15px 25px"
      width="70vw"
      margin="30px 0 0 0"
      key={jobOffer._id}
    >
      {isLoginFormDisplayed && (
        <Login
          isDisplayed={isLoginFormDisplayed}
          setIsDisplayed={setIsLoginFormDisplayed}
          isRegistration={true}
        />
      )}
      <Row wrap justifyContent="space-between" width="100%">
        <a
          onClick={checkLogin}
          target="_blank"
          rel="noreferrer"
          href={`mailto:${jobOffer.contact?.email}`}
        >
          <Button.Primary>Postuler à l'offre d'emploi</Button.Primary>
        </a>

        <Link
          style={{ textDecoration: 'underline', fontSize: '16px', marginTop: '10px' }}
          to={`/profil-cabinet?companyId=${jobOffer.userId}`}
          target="_blank"
        >
          {jobOffer.officeName}
        </Link>
      </Row>
      <InformationsContent>
        {jobOffer.contractType && (
          <Row wrap width="100%">
            <Text
              responsive="width: 100%; margin-bottom: 5px"
              width="45%"
              position="start"
              color="secondary"
            >
              Type de contrat :
            </Text>
            <Text responsive="width: 100%;">{jobOffer.contractType}</Text>
          </Row>
        )}
        {jobOffer.startDate && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Début de missions :
              </Text>
              <H4 responsive="width: 100%;" ellipsis width="50%">
                {jobOffer.startDate}
              </H4>
            </Row>
          </>
        )}
        {jobOffer.salary && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Salaire brut mensuel :
              </Text>
              <H3 responsive="width: 100%;" ellipsis width="50%">
                {jobOffer.salary}
              </H3>
            </Row>
          </>
        )}
        {jobOffer.address && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Adresse :
              </Text>
              <H2 responsive="width: 100%;" ellipsis width="50%">
                {jobOffer.address.street +
                  ', ' +
                  jobOffer.address.postalCode +
                  ', ' +
                  jobOffer.address.region +
                  ', ' +
                  jobOffer.address.country}
              </H2>
            </Row>
          </>
        )}
      </InformationsContent>
      {htmlDescription && (
        <Text margin="20px 0 0 0" dangerouslySetInnerHTML={{ __html: htmlDescription }} />
      )}
    </Card>
  )
}

export default JobOfferCard
