import React from 'react'
import { Helmet } from 'react-helmet'

import { Text, Table } from 'styles/global'
import { Skeletons } from 'components'

import { routes } from 'router'
import { Link } from 'react-router-dom'
import { Space } from 'antd'
import { Container, ContentContainer } from 'pages/myOffers/style'
import { Candidate } from 'types'
import { useAuth } from 'hooks'

type Props = {
  candidates: Candidate[] | undefined
  handleUpdateCandidate: ({
    newCandidate,
    index,
  }: {
    newCandidate: Candidate
    index: number
  }) => void
  isLoading: boolean
}

const CandidateTable = ({ candidates, handleUpdateCandidate, isLoading }: Props) => {
  const { adminLoginToAccount } = useAuth()
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a.name?.localeCompare(b.name),
      },
      render: (_, record) => <Text>{record.candidateInformations?.name}</Text>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <Text>{text}</Text>,
      sorter: {
        compare: (a, b) => a.startDate?.localeCompare(b.startDate),
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Text
            underline
            pointer
            color="#1890ff"
            onClick={() =>
              handleUpdateCandidate({
                newCandidate: {
                  ...record,
                  isPublished: record.isPublished === false ? true : false,
                },
                index,
              })
            }
          >
            Rendre {record.isPublished === false ? 'visible' : 'invisible'}
          </Text>
          <Link to={`${routes.candidateRender.path}?candidateId=${record._id}`}>
            <Text underline pointer color="#1890ff">
              Voir le profil
            </Text>
          </Link>
          <Text
            onClick={() => adminLoginToAccount({ id: record._id })}
            underline
            pointer
            color="#1890ff"
          >
            S'y connecter
          </Text>
        </Space>
      ),
    },
  ]

  return (
    <Container margin="50px 0 0 0">
      <ContentContainer width="80%">
        <Text margin="0 0 0 30px" weight="bold" size="22px" color="secondary">
          Liste des candidats
        </Text>
        {isLoading ? (
          <Skeletons />
        ) : (
          <Table
            pagination={false}
            scroll={{ x: 730, y: 600 }}
            columns={columns}
            dataSource={candidates}
          />
        )}
      </ContentContainer>
    </Container>
  )
}

export default CandidateTable
