import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

import { UserContext } from 'providers'
import { adminServices, authService, cookies } from 'services'
import { authUtils, homeRouteUtils } from 'utils'
import routes from 'router/routes'
import { COOKIES } from 'constants/index'

interface ReturnType {
  login: ({ email, password, redirection, callback }) => void
  adminLoginToAccount: ({ id }) => void
  isLoading: boolean
  logout: () => void
}

const useAuth = (): ReturnType => {
  const { setIsAuthenticated, setUser, user, resetUser } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  // Logout by clearing cookies and context
  const logout = (): void => {
    cookies.clear(COOKIES.TOKEN)
    cookies.clear(COOKIES.EMAIL)
    cookies.clear(COOKIES.TYPE)
    setIsAuthenticated(false)
    resetUser()
    navigate(routes.home.path)
    setIsLoading(false)
  }

  const feedAuthCookies = (data): void => {
    const { token, type, email } = data
    const payload = authUtils.decodedJwt(data.token)
    const setCookies = [
      { key: 'token', value: token },
      { key: 'type', value: type },
      { key: 'email', value: email },
    ]
    cookies.set(setCookies)
  }

  // Login with credentials given by login form
  const login = async ({
    email,
    password,
    redirection,
    callback,
  }: {
    email: string
    password: string
    redirection: string
    callback: () => void
  }): Promise<void> => {
    setIsLoading(true)
    await authService
      .login({
        email,
        password,
      })
      .then(async (data) => {
        if (data.status === 200) {
          feedAuthCookies(data)
          setUser(data.user)
          setIsLoading(false)
          navigate(data.user?.type === 'admin' ? routes.adminDashboard.path : redirection)
          callback()
        } else {
          setIsLoading(false)
          await message.error(t(data.message))
        }
      })
  }

  const adminLoginToAccount = async ({ id }: { id: string }): Promise<void> => {
    setIsLoading(true)
    await adminServices
      .loginToAccount({
        id,
      })
      .then(async (data) => {
        if (data.status === 200) {
          feedAuthCookies(data)
          setUser(data.user)
          setIsLoading(false)
          navigate('/')
          message.success('Connecté avec succés')
        } else {
          setIsLoading(false)
          await message.error(t(data.message))
        }
      })
  }

  return {
    login,
    adminLoginToAccount,
    isLoading,
    logout,
  }
}

export default useAuth
