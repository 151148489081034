import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { Title, Row, Column, Button, Text, H1, H2, H3, H4, Card } from 'styles/global'
import { BadPlan, Skeletons } from 'components'
import { urlParams, authUtils } from 'utils'
import { Col, Divider, message, Modal } from 'antd'
import { jobOfferService, userService } from 'services'
import { useQuery } from 'react-query'
import { Company, JobOffer } from 'types'
import { ProfilePicture, InformationsContent } from './style'
import { UserContext } from 'providers'
import JobOfferList from 'components/jobOfferList'
import Login from 'components/login'

import profileImg from 'assets/icons/building.svg'

const CandidateRender = () => {
  const [isViewContacts, setIsViewContacts] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const [company, setCompany] = useState<Company>()
  const paramsUrl = urlParams()

  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isEndOfList, setIsEndOfList] = useState<boolean>(false)
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  const { isLoading: isOffersLoading } = useQuery(
    ['jobOffers', currentPage, paramsUrl.companyId],
    () =>
      jobOfferService.find({ page: currentPage, companyId: paramsUrl.companyId }).then((res) => {
        setDisplayedJobOffers([...displayedJobOffers, ...res.data])
        if (!res.data?.length) {
          setIsEndOfList(true)
        }
        return [...displayedJobOffers, ...res.data]
      }),
  )

  const { isLoading } = useQuery(
    [paramsUrl.companyId, 'companyProfile'],
    async () => userService.company({ id: paramsUrl.companyId }),
    {
      onSuccess: (res) => {
        setCompany(res)
      },
    },
  )

  const htmlToText = (text) => {
    const content = convertFromRaw(JSON.parse(text))
    //@ts-ignore
    return stateToHTML(content)
  }

  const handleScrollToOffers = () => {
    const firstOfferElem = document.getElementById('job-offer-0')
    if (firstOfferElem) {
      firstOfferElem.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const checkLogin = (e) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(location.pathname + location.search)
    } else {
      setIsViewContacts(true)
    }
  }

  return (
    <Column width="100%" alignItems="center">
      <Helmet>
        <title>Offres d'emploi cabinet {company?.name || ''}</title>
        <meta name="description" content={company?.description || ''} />
        <meta name="keywords" content="cabinet, orthoptiste, profil" />
      </Helmet>
      {isLoginFormDisplayed && (
        <Login
          isDisplayed={isLoginFormDisplayed}
          setIsDisplayed={setIsLoginFormDisplayed}
          isRegistration={true}
        />
      )}
      {isViewContacts && (
        <Modal visible={isViewContacts} onCancel={() => setIsViewContacts(false)} footer={[]}>
          <Column width="100%" padding="50px">
            <Text size="16px" margin="0 0 30px 0">
              Coordonnées de <span style={{ fontWeight: 'bold' }}>{company?.name}</span>
            </Text>
            {company?.email && (
              <Text>
                <span style={{ fontWeight: 'bold' }}>Adresse email : </span> {company.email}
              </Text>
            )}
            {company?.phone && (
              <Text>
                <span style={{ fontWeight: 'bold' }}>Téléphone : </span> {company.phone}
              </Text>
            )}
          </Column>
        </Modal>
      )}
      <Card width="100%">
        {isLoading ? (
          <Skeletons />
        ) : (
          company && (
            <Row responsive="flex-direction: column" justifyContent="center" width="100%">
              <ProfilePicture alt="profil picture" src={company?.profilePicture || profileImg} />
              <Column responsive="max-width: 100%" maxWidth="80%">
                <Row wrap>
                  <Column>
                    <Text color="greyBlack">Cabinet</Text>
                    <Title
                      responsive="max-width: 280px; margin: 0 20px 20px 0"
                      maxWidth="350px"
                      ellipsis
                      size="18px"
                      weight="bold"
                    >
                      {company.name}
                    </Title>
                  </Column>
                  {(company.phone || company.email) && (
                    <Button.Primary
                      onClick={checkLogin}
                      responsive="margin: 10px;"
                      margin="0 40px 0 40px"
                    >
                      Voir les coordonnées
                    </Button.Primary>
                  )}
                </Row>
                <InformationsContent>
                  <Row justifyContent="space-between">
                    <Text>
                      {`${company.address?.street || ''}
                      ${
                        company.address?.postalCode ||
                        ('' && ', ' + company.address?.postalCode) ||
                        ''
                      }
                      ${company.address?.city || ('' && ', ' + company.address?.city) || ''}
                      ${company.address?.country || ('' && ', ' + company.address?.country) || ''}`}
                    </Text>
                    <H2
                      onClick={handleScrollToOffers}
                      size="16px"
                      underline
                      color="#6bb4f4"
                      pointer
                    >
                      Voir les offres d'emplois
                    </H2>
                  </Row>
                  {company.description && (
                    <Column>
                      <Text>Qui sommes nous</Text>
                      <H4
                        dangerouslySetInnerHTML={{ __html: htmlToText(company.description) }}
                      ></H4>
                    </Column>
                  )}
                </InformationsContent>
              </Column>
            </Row>
          )
        )}
      </Card>

      <JobOfferList
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isOffersLoading}
        jobOffers={displayedJobOffers}
        isEndOfList={isEndOfList}
      />
    </Column>
  )
}

export default CandidateRender
