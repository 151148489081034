import { styled, mediaQuery, theme } from 'styles'
import { Column, Card as GlobalCard, H2 } from 'styles/global'
import banner from 'assets/illustrations/banner.png'
import back1 from 'assets/illustrations/home_back.svg'
import back2 from 'assets/illustrations/home_back2.svg'
import back3 from 'assets/illustrations/home_back3.jpg'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const Banner = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  padding-left: 14vw;
  align-items: flex-start;
  flex-direction: column;
  background-image: url(${banner});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 200px;
  ${mediaQuery.mobile} {
    height: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
`

export const Back1 = styled.div`
  width: 100vw;
  padding-left: 14vw;
  padding-right: 14vw;
  background-image: url(${back1});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 60px;
  ${mediaQuery.mobile} {
    flex-wrap: wrap;
  }
`

export const Icon = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-left: 30px;
  margin-right: 30px;
`
export const Card = styled(GlobalCard)`
  background-image: url(${back2});
  background-size: 230%;
  background-repeat: no-repeat;
  background-position: top;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 330px;
  width: 300px;
  min-width: 300px;
  margin: 30px;
  position: relative;
  padding-top: 90px;
  ${mediaQuery.mobile} {
    margin: 20px !important;
  }
`

export const CardTitle = styled(H2)`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  color: white;
  margin: 0 0 60px 0;
  position: absolute;
  top: 10px;
`

export const Back2 = styled(Column)`
  width: 100%%;
  background: ${theme.color.secondary};
  padding: 30px;
  padding-left: 14vw;
  padding-right: 14vw;
  align-items: center;
`

export const Back3 = styled.img.attrs({
  src: back3,
})`
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: -80px;
  background: white;
`

export const Back4 = styled(Column)`
  width: 100%%;
  background: white;
  padding: 30px;
  padding-left: 14vw;
  padding-right: 14vw;
  align-items: center;
`

export const ServiceImg = styled.img`
  height: 120px;
  width: auto;
  margin-top: 40px;
`
