import React from 'react'
import { Helmet } from 'react-helmet'

import { H1, H2, H3, H4, Row } from 'styles/global'
import { Banner, Container, Icon } from './style'
import { ContactForm } from 'components'

import successImg from 'assets/icons/success.png'
import timeImg from 'assets/icons/time.png'

const Contact = () => {
  return (
    <Container>
      <Helmet>
        <title>Orthoptiste-emploi | Service de chasse</title>
        <meta
          name="description"
          content="Prendre contact avec notre service de chasse afin que l'on trouve pour vous les profils orthoptiste dont vous avez besoin."
        />
        <meta name="keywords" content="service, chasse, orthoptiste" />
      </Helmet>
      <Banner>
        <H1 margin="0 0 10px 0" style={{ maxWidth: '80%' }} size="40px" weight="500" color="white">
          Nous mettons notre
          <span style={{ fontWeight: 'bold', margin: '0 11px' }}>expertise</span>à votre
          <span style={{ fontWeight: 'bold', marginLeft: '11px' }}>service</span>
        </H1>
        <H2 style={{ maxWidth: '80%' }} size="36px" weight="400" color="white">
          Laissez nous un message et nous vous trouverons les meilleurs profils orthoptiste
        </H2>
      </Banner>
      <Row width="70%" margin="30px 0 0 0">
        <Icon alt="success" src={successImg} />
        <H3 size="24px" weight="700" color="secondary">
          Rémunération au succès
          <br />
          <span style={{ fontWeight: '500', fontSize: '20px' }}>
            Nous ne sommes payés qu'en cas de recrutement réussi
          </span>
        </H3>
      </Row>
      <Row width="70%" margin="30px 0 0 0">
        <Icon alt="success" src={timeImg} />
        <H4 size="24px" weight="700" color="secondary">
          Délais de recrutement raccourcis
          <br />
          <span style={{ fontWeight: '500', fontSize: '20px' }}>
            Un délai moyen de 3 mois pour recruter le candidat idéal
          </span>
        </H4>
      </Row>
      <ContactForm />
    </Container>
  )
}

export default Contact
