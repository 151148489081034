import React from 'react'
import { Button, Modal } from 'antd'
import { Column, Text } from 'styles/global'
import { theme } from 'styles'

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
  saveCallback: () => void
}

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  saveCallback,
}) => {
  return (
    <Modal
      footer={[
        <Button
          onClick={confirmNavigation}
          style={{
            backgroundColor: '#ff6767',
            color: 'white',
            borderRadius: '4px',
            border: 'none',
          }}
          key="submit"
        >
          Ne pas sauvegarder
        </Button>,

        <Button
          onClick={() => {
            saveCallback()
            cancelNavigation()
          }}
          key="back"
          style={{
            background: theme.color.secondary,
            color: 'white',
            borderRadius: '4px',
            border: 'none',
          }}
        >
          Sauvegarder mes modifications
        </Button>,
      ]}
      onOk={confirmNavigation}
      visible={showDialog}
      onCancel={cancelNavigation}
    >
      <Column alignItems="center" width="100%">
        <Text position="center" size="20px">
          Êtes vous sûr de vouloir quitter sans sauvegarder vos informations ?
        </Text>
      </Column>
    </Modal>
  )
}
export default DialogBox
