import React from "react";

import GetStarted from "../getStarted/GetStarted";
import {Column} from 'styles/global'

const ArticleFooter = () => {
  return (
    <Column width="100%" style={{ marginTop: "50px" }} className="column header-container width-100">
      <p className="title-bold montserrat start" style={{ fontWeight: "800", fontSize: "28px" }}>
        Cabinet ophtalmologique ? <br />
        Orthoptiste ?
      </p>
      <p style={{ marginBottom: "15px", fontSize: "14px" }} className="text-300 grey">
        Trouvez tout ce dont vous avez besoin en terme d'emploi orthoptiste à portée de clic.
      </p>
      <GetStarted />
    </Column>
  );
};

export default ArticleFooter;
