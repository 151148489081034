import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { message } from 'antd'

import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { BadPlan, CandidateCard, Skeletons, CvThequeUnavailable } from 'components'
import { UserContext } from 'providers'
import { userService } from 'services'
import { authUtils } from 'utils'
import { useQuery } from 'react-query'
import Filter, { FilterParams } from 'components/GlobalComponents/filter'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Candidate } from 'types'
import { IS_CVTHEQUE_DISABLED } from 'constants/index'

const CandidateList = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filterParams, setFilterParams] = useState<FilterParams>({
    country: '',
    region: '',
    department: '',
    contractType: '',
  })
  const [displayedCandidats, setDisplayedCandidats] = useState<Candidate[]>()
  const [isEndOfList, setIsEndOfList] = useState<boolean>(false)
  const { user } = useContext(UserContext)

  const isAuthorized = authUtils.isCvThequeAccess({ user })

  const { data: candidates } = useQuery(
    [`candidates${currentPage}`, JSON.stringify(filterParams)],
    async () =>
      userService.candidates({ page: currentPage, ...filterParams }).then((res) => {
        const newResult = displayedCandidats ? [...displayedCandidats, ...res.data] : res.data
        setDisplayedCandidats(newResult)
        if (!res.data?.length) {
          setIsEndOfList(true)
        }
        return newResult
      }),
    {
      enabled: isAuthorized,
    },
  )

  return (
    <Column margin="50px 0 0 0" width="100%" alignItems="center">
      <Helmet>
        <title>Emploi-orthoptiste | cvThèque</title>
        <meta
          name="description"
          content="Liste de profils candidats orthoptiste. Trier par zones géographiques, type de contrat recherché et formation. Obtenir les coordonnées de candidats disponibles et ouverts aux offres d'emploi orthoptiste."
        />
        <meta name="keywords" content="cvtheque, candidat, orthoptiste" />
      </Helmet>
      <Filter setFilterParams={setFilterParams} />

      {IS_CVTHEQUE_DISABLED ? (
        <CvThequeUnavailable />
      ) : isAuthorized ? (
        <InfiniteScroll
          dataLength={displayedCandidats?.length || 0} //This is important field to render the next data
          next={() => setCurrentPage(currentPage + 1)}
          refreshFunction={() => setCurrentPage(currentPage + 1)}
          hasMore={!isEndOfList}
          loader={<Skeletons />}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          <Row wrap width="80%" margin="0 10%" justifyContent="center">
            {displayedCandidats &&
              displayedCandidats.map((candidate) => (
                <CandidateCard key={candidate._id} candidate={candidate} />
              ))}
          </Row>
        </InfiniteScroll>
      ) : (
        <BadPlan />
      )}
    </Column>
  )
}

export default CandidateList
