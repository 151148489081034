import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Divider } from 'antd'
import { Helmet } from 'react-helmet'

import { Container, Banner, Card, Back1, CardTitle, Back2, Back3, Back4, ServiceImg } from './style'
import { Row, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { windowSizes } from 'styles/mediaQuery'
import { UserContext } from 'providers'
import { authUtils } from 'utils'
import Login from 'components/login'
import { ACCOUNT_TYPES } from 'constants/index'
import { JobOffersHome } from 'components'

import cvThequeImg from 'assets/illustrations/cvTheque.png'
import serviceImg from 'assets/illustrations/service.png'
import publishImg from 'assets/illustrations/publish.png'

const Home = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < windowSizes.mobile)
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const isConnected: boolean = authUtils.isAuth()

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
    )
    return () =>
      window.removeEventListener('resize', () =>
        window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
      )
  }, [])

  const handleCandidatRedirect = (e) => {
    if (!isConnected) {
      e.stopPropagation()
      e.preventDefault()
      setIsLoginFormDisplayed(routes.candidateAccount.path)
    }
  }

  const handleCompanyRedirect = (path, e) => {
    if (!isConnected) {
      e?.stopPropagation()
      e?.preventDefault()
      setIsLoginFormDisplayed(path)
    }
  }

  const handleScrollToServices = () => {
    document.getElementById('recruteurs')?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      {isLoginFormDisplayed && (
        <Login isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      <Helmet>
        <title>Portail emploi Orthoptiste | Publier et retrouver les offres d'emploi</title>
        <meta
          name="description"
          content="Publier ses offres d'emploi orthoptiste sous forme d'annonce et retrouver notre cvThèque de profils candidats afin de recruter des orthoptistes rapidement. Publiez votre profil orthoptiste et mettez vous en avant auprès des meilleurs cabinets."
        />
        <meta name="keywords" content="orthoptiste, emploi, recrutement" />
      </Helmet>
      <Banner>
        <H1 margin="0 0 20px 0" style={{ maxWidth: '90vw' }} size="40px" weight="500" color="white">
          Le portail emploi <span style={{ fontWeight: 'bold' }}>orthoptiste</span>
          <br />
          <span style={{ fontSize: '32px' }}>Pour les cabinets et pour les candidats</span>
        </H1>
        {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
          <Button.Secondary weight="600" fontSize="18px" margin="20px 0 25px 0">
            <a onClick={handleScrollToServices}>Services aux recruteurs</a>
          </Button.Secondary>
        )}
        <Row>
          <Button.Secondary weight="600" fontSize="18px" margin="0 20px 0 0">
            <Link to={routes.viewOffers.path}>Offres d’emploi</Link>
          </Button.Secondary>
          {authUtils.currentRole() !== ACCOUNT_TYPES.COMPANY && (
            <Button.Secondary
              onClick={handleCandidatRedirect}
              weight="600"
              fontSize="18px"
              style={{ color: '#1890ff' }}
            >
              {isConnected ? (
                <Link to={routes.candidateAccount.path}>Modifier mon profil</Link>
              ) : (
                'Déposer mon CV'
              )}
            </Button.Secondary>
          )}
        </Row>
      </Banner>
      <JobOffersHome />
      <Back1 id="recruteurs">
        <Card>
          <CardTitle style={{ top: '30px' }}>CV thèque</CardTitle>
          <ServiceImg alt="cvTheque image" src={cvThequeImg} />
          <H2 margin="0 0 80px 0">
            Accéder à la liste de tous les <b>profils Orthoptiste</b> sur notre site ainsi que leurs
            informations et coordonnées
          </H2>
          <Button.Primary
            style={{ position: 'absolute', bottom: '20px' }}
            margin="20px 0 0 0"
            fontSize="18px"
            width="80%"
          >
            <Link style={{ color: 'white' }} to={routes.candidateList.path}>
              Consulter
            </Link>
          </Button.Primary>
        </Card>
        <Card style={{ marginTop: '-20px' }}>
          <CardTitle>Déposer une annonce</CardTitle>
          <ServiceImg alt="publier une offre" src={publishImg} />
          <H4 margin="10px 0 80px 0">
            <b>Publiez votre offre d’emploi Orthoptiste</b> sur notre site pendant 1 mois et obtenez
            de la visibilité ultra ciblée.
          </H4>
          <Button.Primary
            style={{ position: 'absolute', bottom: '20px' }}
            margin="20px 0 0 0"
            fontSize="18px"
            width="80%"
            onClick={(e) => handleCompanyRedirect(routes.createOffer.path, e)}
          >
            {isConnected ? (
              <Link style={{ color: 'white' }} to={routes.createOffer.path}>
                Publier une offre
              </Link>
            ) : (
              'Publier une offre'
            )}
          </Button.Primary>
        </Card>
        <Card>
          <CardTitle style={{ top: '10px' }}>
            Service de
            <br /> chasse
          </CardTitle>
          <ServiceImg
            style={{ marginTop: '20px' }}
            alt="service de chasse illustration"
            src={serviceImg}
          />
          <Text margin="0 0 80px 0">
            Faites nous confiance afin de trouver les profils qui correspondent à vos besoins
          </Text>

          <Button.Primary
            style={{ position: 'absolute', bottom: '20px' }}
            margin="20px 0 0 0"
            fontSize="18px"
            width="80%"
          >
            <Link style={{ color: 'white' }} to={routes.contact.path}>
              Contacter
            </Link>
          </Button.Primary>
        </Card>
      </Back1>
      <Back2>
        <H2 size="28px" weight="600" color="white">
          Trouver le cabinet <b>d'ophtalmologie</b> idéal
        </H2>
        <Text size="16px" margin="30px 0" color="white">
          Le site emploi-orthoptiste.fr référence de nombreuses offres d'emploi de la fonction{' '}
          <b>orthoptiste en France</b>, en CDI ou en CDD, organisées par région et par département.
          <br />
          <br />
          Le site vous permet également de consulter les pages des{' '}
          <b>cliniques ou des centres de santé qui recrutent</b> pour avoir un maximum
          d'informations avant de postuler.
          <br /> <br />
          Les annonces sont mises à jour régulièrement, pour garantir aux candidats que les offres
          sont toujours d'actualité. Vous pouvez aussi <b>déposer votre CV en ligne</b>, et laissez
          les recruteurs vous contacter directement en cliquant{' '}
          <Link
            style={{ color: 'white', textDecoration: 'underline' }}
            onClick={handleCandidatRedirect}
            to={routes.candidateAccount.path}
          >
            ici.
          </Link>
        </Text>

        {authUtils.currentRole() !== ACCOUNT_TYPES.COMPANY && (
          <Button.Secondary
            onClick={handleCandidatRedirect}
            weight="600"
            fontSize="18px"
            style={{ color: '#1890ff' }}
          >
            {isConnected ? (
              <Link to={routes.candidateAccount.path}>Déposer mon CV</Link>
            ) : (
              'Déposer mon CV'
            )}
          </Button.Secondary>
        )}
      </Back2>
      <Back4>
        <H3 size="28px" weight="600" color="generalSecondary">
          Recruter le candidat orthoptiste dont vous avez besoin
        </H3>
        <Text size="16px" margin="30px 0" color="generalSecondary">
          Avec une audience de plusieurs centaines d'orthoptistes qui visitent le site tous les
          mois, nous vous offrons une plateforme ciblée et performante, pour <b>recruter</b> dans ce
          secteur.
          <br /> <br />
          Emploi-orthoptiste.com vous propose plusieurs services: la diffusion de vos offres
          d'emploi sur le site et nos réseaux sociaux, un accès à une Cvthèque d'orthoptiste et un
          accompagnement personnalisé dans le recrutement orthoptiste pour votre structure.
          <br />
          <br />
          Une équipe dédiée est là pour répondre à vos questions en cas de besoin, au numéro
          suivant: 06.51.18.75.01
          <br />
          N'attendez plus, et passez à l'étape suivante dans votre <b>recrutement orthoptiste</b>.
        </Text>

        {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
          <Row wrap={true} justifyContent="center">
            <Link style={{ margin: '10px' }} to={routes.candidateList.path}>
              <Button.Primary>Accéder à la cvThèque</Button.Primary>
            </Link>
            <Link
              onClick={(e) => handleCompanyRedirect(routes.createOffer.path, e)}
              style={{ margin: '10px' }}
              to={routes.createOffer.path}
            >
              <Button.Primary>Déposer une offre d'emploi</Button.Primary>
            </Link>

            <Link style={{ margin: '10px' }} to={routes.contact.path}>
              <Button.Primary>Service de chasse </Button.Primary>
            </Link>
          </Row>
        )}
      </Back4>
      <Back3 alt="portail offre emploi orthoptiste" />
    </Container>
  )
}

export default Home
